/* ReactBigCalendar.css */

.container {
    padding: 5px;
  }
  
  .button-primary {
    background-color: #007bff;
    border: none;
    color: white;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  .button-primary:hover {
    background-color: #0056b3;
  }
  
  .modal-header {
    background-color: #f8f9fa;
  }
  
  .modal-title {
    font-size: 1.5rem;
    color: #343a40;
  }
  
  .modal-body {
    padding: 20px;
  }
  
  .form-label {
    font-weight: bold;
  }
  
  .form-control {
    border-radius: 5px;
    border-color: #ced4da;
    padding: 10px;
  }
  

  
  .react-big-calendar {
    border: 1px solid #ced4da;
    border-radius: 5px;
  }
  .rbc-event:hover {
    background-color: #c5d5e6;
    cursor: pointer;
  }


/* ReactBigCalendar.css */

.dot {
    height: 10px;
    width: 10px;
    background-color: #2039c1;
    border-radius: 50%;
    display: inline-block;
    margin-right: 5px; /* Espace entre le point et le texte */
    vertical-align: middle; /* Alignement vertical */
  }
  
  .rbc-event {
    display: flex; /* Utiliser flexbox pour aligner le point et le texte */
    align-items: center; /* Alignement vertical du texte par rapport au point */
    background-color: transparent; /* Assurez-vous que le fond de l'événement est transparent */
    padding: 1px; /* Ajoutez un peu de padding pour espacer le texte */
    border-radius: 5px;
    font-size: 12px;
    color: #101010; /* Couleur du texte */
    white-space: nowrap; /* Empêcher le texte de se casser en plusieurs lignes */
  }
  